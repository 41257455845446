<template>
    <div class=" w-100 d-flex flex-column flex-sm-row justify-sm-center  justify-md-space-between  pb-2 pt-2 pr-1" style="box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;">
        <div id="component-search-customer-seller">
            <v-form ref="form" class="d-flex align-items-center align-center">
                <a @click="goBackAction()" v-if="showGoback" class="pa-2">  <img src="img/arrow-u-left-top.svg"></a>
                <label class="ml-4 mr-2">Búsqueda:</label>              
                <v-text-field  hide-details="auto" large dense solo required 
                    v-model="searchInput" 
                    class="text-ped pt-0  mb-0" 
                    :disabled="loading" 
                    inputmode="text"
                    @keydown="validateCharacters"
                    @keyup.enter="sendCode()" 
                    type="text"                                           
                >
                <template v-slot:append>
                        <v-btn v-if="searchInput" icon @click="clearInput" :disabled="loading">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
                <div class="ml-4">
                    <v-btn :loading="loading" @click="sendCode()" class="btn-danger btn-custom rounded-pill" block>Consultar</v-btn>
                </div>
            </v-form>
        </div>
        <div class="d-flex justify-center align-center pt-3 pt-sm-0 ml-3 ml-sm-1">
                <slot name="right-actions" ></slot>  
        </div>
        <v-dialog v-model="showResult" :persistent="loading" transition="dialog-bottom-transition"  max-width="800" > 
            <v-card>
                <v-toolbar  dark >
                    Resultado de la búsqueda '{{ searchInput }}'
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                        dense
                        :loading="loading"
                        loading-text="Buscando información..."
                        :headers="headers"
                        :items="searchResult"
                        class="elevation-1"
                        no-results-text="No se han encontrado conicidencias">
                            <template  v-slot:[`item.P_IDENTIFICA`]="{ item }"> 
                                    {{ availableRoles(item.P_IDENTIFICA) }} 
                            </template>
                            <template  v-slot:[`item.P_NAME`]="{ item }">
                                <a class="text-decoration-underline" @click="goToUser(item)"> {{ item.P_NAME }}  </a> 
                            </template>
                            <template  v-slot:[`item.P_USERID`]="{ item }">
                                <a class="text-decoration-underline" @click="goToUser(item)"> {{ item.P_USERID }}  </a> 
                            </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn  text  @click="showResult = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card> 
      </v-dialog>
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
import numbersFormats from "@/mixins/numbersFormats";
export default {
    props: {
        showGoback: {
            type: Boolean,
            default:true, 
        },
        customGobackAction:{
            type: Boolean,
            default:false, 
        },
    },
    mixins:[numbersFormats],
    data(){
        return{
            searchInput: null,
            customer_code: null,
            loading:false,
            searchResult:[],
            showResult:false,
            headers: [
            { text: 'ID', align: 'start', sortable: true, value: 'P_USERID', },
            { text: 'Nombre', align: 'start', sortable: true, value: 'P_NAME' },
            { text: 'Rol', align: 'start', sortable: true,value: 'P_IDENTIFICA' }, 
        ],
        allowedKeys:[
        ..."ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzñ0123456789 ",  "Backspace", "ArrowLeft", "ArrowRight", "Tab" 
        ]
        }
    },  
    computed:{
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        }),
    },  
    methods:{
        validateCharacters(event) { 
            if (!this.allowedKeys.includes(event.key)) {
                event.preventDefault();
            } 
        },
        goToUser(user){
            var userId = "";
            switch (user.P_IDENTIFICA) {
                case 'C':  
                    userId = this.removeLeftZeros(user.P_USERID);
                    this.$setLocalStorageCustomer(userId)          
                    this.$router.push({name: "CustomerCoverDetails"})
                break;
                case 'A':  
                    userId = this.fillNumberToNDigits(user.P_USERID,8);
                    this.$setLocalStorageSeller(userId)   
                    this.$router.push({name: "SellerResults"})
                break;
                case 'G':  
                case 'SG':  
                    userId = this.fillNumberToNDigits(user.P_USERID,8);
                    this.$setLocalStorageManager(userId);  
                    this.$router.push({ name: "ManagerHome" });
                break;
            }
        },
        availableRoles(role){
            switch (role) {
                case 'C': return 'Cliente';
                case 'A': return 'Asesor';
                case 'G': return 'Gerente';
                case 'SG': return 'Sub Gerente';
            }
            return role; 
        },
        clearInput() {
            this.$refs.form.reset();
        },
        getUserCode() { 
            //Se toma el gerente regional o el canal. Actualizar si se requiere a nivel gerencia/subgerencia
            const rgManager = this.$getLocalStorageRegional(); 
            if (
            rgManager && this.$route.meta.role == 'Regional' 
            && !this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
            return `${rgManager}`;
            }  
            if (this.$hasAnyRole(["RegionalManager", "IELRegManager"])) {
            return `${localStorage.getItem("username")}`; 
            }
    },
        async sendCode(){            
            if (this.validate()) {  
                    let params =  { 
                            P_USRSCH: this.getUserCode(),
                            P_SEARCH: this.searchInput,
                            P_VTWEG: this.user.VTWEG, 
                        }  
                    this.loading = true;
                    this.showResult = true;
                    this.searchResult = []; 
                    try{
                    let response = await axios.get('/search-user',{  params :params})
                    this.loading = false;
                    const res =response.data.data;
                    this.searchResult = res?.P_IDENTIFICA ? [res] : res;
                    this.showResult = true; 
                 }catch(error){
                    this.loading = false;
                    this.$toast.error('Ocurrió un error al consultar el código. Por favor inténtelo de nuevo')
                }
            }
        },
        goBackAction(){
            this.customGobackAction ? this.$emit('goBackAction'): this.callback();            
        },
        validate(){
            const isFormValid = this.$refs.form.validate();
            return (this.searchInput && this.searchInput.trim() !== '' && isFormValid)
        }
    }
}
</script>

<style >
    #component-search-customer-seller .btn-custom{
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
          opacity: 1;
          color: #FFF !important;
          text-transform: inherit !important;
          font-size: 12px !important;

    } 
</style>